$footer-b-1: 1000px;

.footer {
	margin-top: var(--vertical-spacing);
	color: var(--color-two);
	background-color: var(--color-one);
	background-color: var(--color-five);

	&__newsletter {}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 1.5rem;
		padding-bottom: 3rem;
	}

	&__text {
		width: 100%;
		border-bottom: 1px solid var(--color-eleven);
		padding-bottom: 1.5rem;
		margin-bottom: 2rem;

		@media (min-width: $footer-b-1) {
			width: calc(100% - 14rem);
			border-bottom: unset;
			padding-bottom: unset;
			margin-bottom: unset;
		}
	}

	&__heading {
		@include font-preset--2;
		margin: 0;
	}

	&__icons {}

	&__text-links {
		margin-top: 0.5ex;

		a {
			text-decoration: underline;

			&:hover {
				color: var(--color-twelve);
			}
		}
	}

	&__text-links-separator {
		opacity: 0.3;
		font-size: 1.7rem;
		font-weight: 100;
		padding: 0 0.4rem;
	}

	&__logo  {
		max-width: 8rem;

		@media (min-width: $footer-b-1) {
			max-width: 12rem;
		}
	}

	&__lower {
		margin-top: 2.5rem;
		padding-top: 1.5rem;
		width: 100%;
		border-top: 1px solid var(--color-eleven);

		@media (min-width: $footer-b-1) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		p {
			margin: 0 0 1rem;

			@media (min-width: $footer-b-1) {
				margin: 0;
			}
		}
	}
}




