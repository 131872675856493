$alternating-b-1: 800px;

.alternating {

	+.alternating {
		margin-top: calc(var(--vertical-spacing) / 2);
	}

	@media (min-width: $alternating-b-1) {
		&--flip {
			.alternating__container {
				flex-direction: row-reverse;
			}

			.alternating__text {
				transform: translate(2rem, 0);
			}

			.alternating__image {
				transform: translate(-2rem, 0);
			}
		}
	}

	&--in-viewport {
		.alternating__text,
		.alternating__image {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> * {
			transition: transform 0.4s, opacity 0.35s;
		}
	}

	&__text {
		width: 100%;
		opacity: 0;
		transform: translate(0, 2rem);

		@media (min-width: $alternating-b-1) {
			width: calc(50% - 1.25rem);
			transform: translate(-2rem, 0);
		}
	}

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: var(--color-ten);
	}

	&__copy {
		margin-top: 1.3ex;
	}

	&__image {
		width: 100%;
		opacity: 0;
		transform: translate(0, 2rem);
		margin-top: 1.5rem;

		@media (min-width: $alternating-b-1) {
			margin-top: unset;
			width: calc(50% - 1.25rem);
			transform: translate(2rem, 0);
		}
	}

	&__image-caption {
		font-size: 0.87rem;
		max-width: unset;
		margin-top: 0.8ex;
	}
}