@font-face {
	font-family: 'Trunk';
	src: url('/assets/fonts/Trunk-Regular.eot');
	src: url('assets/fonts/Trunk-Regular.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/Trunk-Regular.woff') format('woff2'),
		url('/assets/fonts/Trunk-Regular.woff') format('woff'),
		url('/assets/fonts/Trunk-Regular.ttf') format('truetype'),
		url('/assets/fonts/Trunk-Regular.svg') format('svg');
	font-weight: 800;
	font-style: regular;
	font-display: swap;
}