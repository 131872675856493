.edge {
	position: absolute;
	left: 0;
	width: 100%;

	path {
		fill: var(--color-three);
		fill: inherit;
	}

	// The -1px is to prevent a super thin white line at some screen widths
	&--top {
		bottom: calc(100% - 1px);
	}

	&--bottom {
		transform: rotate(180deg);
		top: calc(100% - 1px);
	}
}