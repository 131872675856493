$company-cards-b-1: 700px;
$company-cards-b-2: 500px;

.company-cards {
	&__container {}

	&__section-heading {
		@include font-preset--3;
		margin: 0 0 0.6ex;
		color: var(--color-six);
		text-align: center;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0 -1.25rem;

		> * {
			width: calc(100% - 2.5rem);
			margin: 0 1.25rem 1.25rem;

			@media (min-width: $company-cards-b-2) {
				width: calc(50% - 2.5rem);
				margin: 0 1.25rem 2.5rem;
			}
		}

		&--three {
			> * {
				width: calc(100% - 2.5rem);

				@media (min-width: $company-cards-b-2) {
					width: calc(50% - 2.5rem);
					margin: 0 1.25rem 2.5rem;
				}

				@media (min-width: $company-cards-b-1) {
					width: calc(33.33% - 2.5rem);
				}
			}

			.company-cards__heading {
				padding: 0.5ex 1ex;

				@media (min-width: $company-cards-b-2) {
					padding: 1.25ex 1ex;
				}
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
		background-color: var(--color-three);

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}

		&:nth-child(5n) {
			background-color: var(--color-eight);
		}

		&:nth-child(5n - 1) {
			background-color: var(--color-seven);
		}

		&:nth-child(5n - 2) {
			background-color: var(--color-nine);
		}

		&:nth-child(5n - 3) {
			background-color: var(--color-five);
		}

		&:nth-child(5n - 4) {
			background-color: var(--color-four);
		}
	}

	&__heading {
		@include font-preset--6;
		margin: 0;
		color: var(--color-two);
		padding: 1.2ex 1ex;

		@media (min-width: $company-cards-b-2) {
			padding: 2ex 1ex;
		}
	}

	&__line {
		border-bottom: 10px dotted var(--color-eleven);
		margin: 1.25rem 0 2.5rem;
	}
}

