.project-nav {
	background-color: var(--color-six);
	padding: 1rem 0;
	position: sticky;
	bottom: 0;
	margin-top: var(--vertical-spacing);
	
	&--last {}

	@media (min-width: 500px) {
		padding: 1.5rem 0;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__button {
		background-color: var(--color-two);
		color: var(--color-one);
		font-size: 1rem;

		&--hidden {
			pointer-events: none;
			opacity: 0;
			height: 0;
			overflow: hidden;
		}

		@media (min-width: 500px) {
			font-size: 1.2rem;
		}
	}
}
