.icons {
	list-style: none;
	margin: 1.75rem 0 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__item {
		margin: 0;
		width: 1.9rem;
		margin-right: 1rem;

		&:hover {
			.icons__icon {
				filter: unset;
			}
		}
	}

	&__link {}

	&__icon {
		filter: grayscale(1) contrast(10);
	}
}