$cards-b-1: 800px;
$cards-b-2: 525px;

.cards {
	&__container {}

	&__section-heading {
		@include font-preset--3;
		margin: 0 0 0.7ex;
		color: var(--color-six);
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0 -1.25rem;
	}

	&__card {
		width: calc(100% - 2.5rem);
		margin: 0 1.25rem 2.5rem;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}

		@media (min-width: $cards-b-2) {
			width: calc(50% - 2.5rem);
		}

		@media (min-width: $cards-b-1) {
			width: calc(33.33% - 2.5rem);
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		color: inherit;

		&:hover,
		&:focus {
			img {
				transform: scale(1.02) rotate(1.25deg);
			}

			.cards__heading {
				color: var(--color-four);
			}
		}
	}

	img {
		transition: transform 0.3s;
	}

	&__heading {
		@include font-preset--5;
		margin-top: 0.8ex;
		color: var(--color-three);
		transition: color 0.3s;
	}

	&__intro {
		margin-top: 1ex;
	}
}