$--stat-block-break-one: 700px;

.stat-block {
	padding: 3.5rem 0;
	background-color: var(--color-three);
	background-image: url('/assets/img/background-texture.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	color: var(--color-two);

	+ .call-out {
		margin-top: 0;
	}

	+ .stat-block {
		margin-top: 0;
	}

	&__container {}

	&__intro {
		margin-bottom: 4rem;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__section-heading {
		@include font-preset--8;
		margin: 0;
	}

	&__stat {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&:not(&:last-of-type) {
			border-bottom: 10px dotted;
			padding-bottom: 4rem;
			margin-bottom: 4rem;

			&::after {
				content: "";
				position: absolute;
				height: 2rem;
				width: 100%;
				background-color: var(--color-two);
				bottom: -1rem;
				left: calc(0rem - var(--container-spacing));
				right: calc(0rem - var(--container-spacing));
				width: auto;
			}
		}

		&--in-viewport {
			.stat-block__number,
			.stat-block__copy {
				transform: translate(0, 0);
				opacity: 1;
			}
			
			.stat-block__copy {
				transition-delay: 0.3s;
			}
		}
	}

	&__number {
		width: 100%;
		transform: translateY(2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
		text-align: center;

		p {
			@include font-preset--11;
			margin: 0;
			line-height: 0.8;
			color: var(--color-nine);
		}

		@media (min-width: $--stat-block-break-one) {
			width: 50%;
		}
	}

	&__copy {
		width: 100%;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
		margin-top: 1rem;

		p {
			@include font-preset--7;
			margin-top: 0;
		}

		@media (min-width: $--stat-block-break-one) {
			transform: translate(2rem, 0);
			width: 50%;
			margin-top: unset;
		}
	}
}

