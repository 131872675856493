.button {
	@include font-preset--5;
	margin: 0;
	appearance: none;
	border: unset;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	padding: 0.4ex 0.8ex 0.3ex;
	background-color: var(--color-three);
	color: var(--color-two);
	transition: background-color 0.2s;

	&:hover {
		background-color: var(--color-nine);
	}

	&--preset-2 {
		background-color: var(--color-eight);

		&:hover {
			background-color: var(--color-seven);
		}
	}

	&--small {
		font-size: 1.6rem;
	}
}