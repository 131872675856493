.hero {
	background-color: var(--color-four);
	color: var(--color-two);

	&--blue {
		background-color: var(--color-five);

		.hero__edge {
			fill: var(--color-five);
		}
	}

	&__image {}

	&__container {
		padding-top: 0.5rem;
		padding-bottom: 1rem;
	}

	&__edge {
		fill: var(--color-four);
	}

	&__heading {
		@include font-preset--1;
		margin: 0;
		transition: transform 0.4s, opacity 0.35s;
	}

	&__intro {
		transition: transform 0.4s, opacity 0.35s 0.2s;
		margin: 1ex 0 2ex;
	}

	&__heading,
	&__intro {
		text-align: center;
		transform: translate(0, 2rem);
		opacity: 0;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
}