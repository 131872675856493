$intro-b-1: 720px;

.intro {
	transform: translate(0, 2rem);
	opacity: 0;
	transition: transform 0.4s, opacity 0.35s;

	&--in-viewport {
		transform: translate(0, 0);
		opacity: 1;
	}

	
		&--with-image {
			.intro__container > *:not(.intro__image) {
				@media (min-width: $intro-b-1) {
					padding-right: calc(25% + 2rem);
				}
			}
		}
		
		&__image {
			max-width: 200px;
			margin-top: 2rem;

			@media (min-width: $intro-b-1) {
				margin-top: 0;
				position: absolute;
				top: 4.5rem;
				right: var(--container-spacing);
				width: calc(25% - var(--container-spacing));
			}
		}
	
	
	+* {
		margin-top: calc(var(--vertical-spacing) / 2) !important;
	}

	&__container {}

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: var(--color-ten);
	}

	&__copy {
		@include font-preset--9;
		margin-top: 1.4ex;
	}

	&__button {
		margin-top: 2ex;
	}
}