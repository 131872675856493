.bold,
p strong {
	font-weight: 800;
}

.italic,
p em {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.upper {
	text-transform: uppercase;
}

.hidden {
	display: none;
}

.image-preload {
	height: 0;
	overflow: hidden;
}

.grad {
	height: 6px;
	width: 100%;
	background: rgb(234,26,72);
	background: linear-gradient(90deg, rgba(234,26,72,1) 0%, rgba(186,52,145,1) 25%, rgba(20,132,196,1) 75%, rgba(28,177,178,1) 100%);
}


.color {

	&--one {
		background-color: var(--color-four);
		color: var(--color-two);
	}

	&--two {
		background-color: var(--color-five);
		color: var(--color-two);
	}

	&--three {
		background-color: var(--color-six);
		color: var(--color-two);
	}

	&--four {
		background-color: var(--color-seven);
		color: var(--color-two);
	}

	&--five {
		background-color: var(--color-eight);
	}
	
	&--six {
		background-color: var(--color-nine);
		color: var(--color-two);
	}
	
	&--seven {
		background-color: var(--color-ten);
		color: var(--color-two);
	}
	
	&--eight {
		background-color: var(--color-eleven);
		color: var(--color-two);
	}
	
	&--nine {
		background-color: var(--color-twelve);
		color: var(--color-two);
	}

	&--ten {
		background-color: var(--color-one);
		color: var(--color-two);
	}

	&--eleven {
		background-color: var(--color-thirteen);
		color: var(--color-two);
	}
}


