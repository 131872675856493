.container {
	width: 100%;
	margin: auto;
	padding-left: var(--container-spacing);
	padding-right: var(--container-spacing);

	&--two {
		max-width: 77rem;
	}
}


