$font-b-1: 1100px;
$font-b-2: 700px;
//1.3


@mixin font-preset--1 {
	font-family: var(--font-two);
	font-size: 3.4rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 4.5rem;
	}

	@media (min-width: $font-b-1) {
		font-size: 5.9rem;
	}
}

@mixin font-preset--2 {
	font-family: var(--font-two);
	font-size: 2.5rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 3rem;
	}

	@media (min-width: $font-b-1) {
		font-size: 3.9rem;
	}
}

@mixin font-preset--3 {
	font-family: var(--font-two);
	font-size: 1.9rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 2.4rem;
	}

	@media (min-width: $font-b-1) {
		font-size: 3rem;
	}
}

@mixin font-preset--4 {
	font-family: var(--font-two);
	font-size: 1.9rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 2.5rem;
	}
}

@mixin font-preset--5 {
	font-family: var(--font-two);
	font-size: 1.5rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 2rem;
	}
}

@mixin font-preset--6 {
	font-family: var(--font-two);
	font-size: 1.1rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 1.5rem;
	}
}

@mixin font-preset--7 {
	font-family: var(--font-one);
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.5;

	@media (min-width: $font-b-2) {
		font-size: 1.8rem;
	}
}

@mixin font-preset--8 {
	font-family: var(--font-one);
	font-size: 1.15rem;
	font-weight: 600;
	line-height: 1.5;

	@media (min-width: $font-b-2) {
		font-size: 1.25rem;
	}
}

@mixin font-preset--9 {
	font-family: var(--font-one);
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 1.5;

	@media (min-width: $font-b-2) {
		font-size: 1.25rem;
	}
}

@mixin font-preset--10 {
	font-family: var(--font-one);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;

	@media (min-width: $font-b-2) {
		font-size: 1.12rem;
	}
}

@mixin font-preset--11 {
	font-family: var(--font-two);
	font-size: 5rem;
	line-height: 1.4;

	@media (min-width: $font-b-2) {
		font-size: 7rem;
	}

	@media (min-width: $font-b-1) {
		font-size: 10.6rem;
	}
}


h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	@include font-preset--10;

	margin: 2.6ex 0 0;
	line-height: 1.5;
	letter-spacing: 0.05ex;

	strong {
		font-weight: 700;
	}
}

p {
	max-width: 100ch;
}

a:not([class]) {
	color: currentColor;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-color: var(--color-one);
	transition: color 0.2s ease;
	text-decoration-skip-ink: auto;

	// &:hover {
	// 	color: var(--color-one);
	// }
}

ul,
ol {
	list-style: disc;
	padding-left: 1.8ex;
}

li {
	margin: 2.6ex 0 0;

	+ li {
		margin: 1.6ex 0 0;
	}
}

.font-preset--1,
h1 {
	@include font-preset--1;
}

.font-preset--2,
h2 {
	@include font-preset--2;
}

.font-preset--3,
h3 {
	@include font-preset--3;
}

.font-preset--4,
h4 {
	@include font-preset--4;
}

.font-preset--5,
h5 {
	@include font-preset--5;
}

.font-preset--6,
h6 {
	@include font-preset--6;
}

